import { combineReducers } from "redux";

import authReducer from "./authReducer";
import gameReducer from "./gameReducer";
import playerReducer from "./playerReducer";
import matchesReducer from "./matchesReducer";
import storiesReducer from "./storiesReducer";
import storyReducer from "./storyReducer";
import messageReducer from "./messageReducer";
import formErrorsReducer from "./formErrorsReducer";
import confReducer from "./confReducer";

import { LOGOUT_USER } from "../actions/types";

const appReducer = combineReducers({
  auth: authReducer,
  message: messageReducer,
  formErrors: formErrorsReducer,
  game: gameReducer,
  player: playerReducer,
  matches: matchesReducer,
  stories: storiesReducer,
  story: storyReducer,
  configuration: confReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
