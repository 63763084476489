import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import EventBus, {
  EVENT_NEXT_STEP,
  EVENT_SCROLL_TOBOTTOM
} from "../../../util/EventBus";

import TextField from "./TextField";
import OptionsField from "./OptionsField";
import OptionsMultipleField from "./OptionsMultipleField";
import ActionsField from "./ActionsField";
import PopupField from "./PopupField";
import PopupMultipleField from "./PopupMultipleField";

import defaultConfiguration from "../DefaultConfiguration";

import "./input.css";

class Input extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Scroll only if there was no input, and a new one appeared
    if (
      Object.keys(prevProps.input).length === 0 &&
      Object.keys(this.props.input).length > 0
    ) {
      EventBus.emit(EVENT_SCROLL_TOBOTTOM, null, "client");
    }
  }

  onSubmit(data) {
    EventBus.emit(EVENT_NEXT_STEP, data, "client");
  }

  getConfiguration() {
    const { type } = this.props.input;

    var configuration = _.merge({}, defaultConfiguration["input"][type]);
    if (
      this.props.configuration &&
      this.props.configuration["input"] &&
      this.props.configuration["input"][type]
    ) {
      _.merge(configuration, this.props.configuration["input"][type]);
    }

    return configuration;
  }

  renderInput() {
    var configuration = this.getConfiguration();

    const { type } = this.props.input;
    if (type === "text") {
      return <TextField name="value" onSubmit={this.onSubmit} />;
    } else if (type === "options") {
      if (this.props.input.multiple) {
        return (
          <OptionsMultipleField
            options={this.props.input.options}
            onSubmit={this.onSubmit}
            styleOption={configuration.styleOption}
            styleOptionElement={configuration.styleOptionElement}
            styleOptionElementSelected={
              configuration.styleOptionElementSelected
            }
          />
        );
      } else {
        return (
          <OptionsField
            options={this.props.input.options}
            onSubmit={this.onSubmit}
            styleOption={configuration.styleOption}
            styleOptionElement={configuration.styleOptionElement}
            styleOptionElementSelected={
              configuration.styleOptionElementSelected
            }
          />
        );
      }
    } else if (type === "popup") {
      if (this.props.input.multiple) {
        return (
          <PopupMultipleField
            options={this.props.input.options}
            onSubmit={this.onSubmit}
            styleOption={configuration.styleOption}
            styleOptionElement={configuration.styleOptionElement}
            styleOptionElementSelected={
              configuration.styleOptionElementSelected
            }
          />
        );
      } else {
        return (
          <PopupField
            options={this.props.input.options}
            onSubmit={this.onSubmit}
            styleOption={configuration.styleOption}
            styleOptionElement={configuration.styleOptionElement}
            styleOptionElementSelected={
              configuration.styleOptionElementSelected
            }
          />
        );
      }
    } else if (type === "actions") {
      return (
        <ActionsField
          styleAction={configuration.styleAction}
          styleActionElement={configuration.styleActionElement}
          styleActionElementSelected={configuration.styleActionElementSelected}
        />
      );
    }
  }

  render() {
    // If props.input is undefined or empty, return nothing
    if (
      this.props.input === undefined ||
      (typeof this.props.input === "object" &&
        Object.keys(this.props.input).length === 0)
    ) {
      return null;
    }

    const formInput = this.renderInput();

    return <div className="chat-input">{formInput}</div>;
  }
}

Input.propTypes = {
  input: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  input: state.story.input,
  configuration: state.story.configuration
});

export default connect(
  mapStateToProps,
  null
)(Input);
