import React, { Component } from "react";
import nl2br from "react-nl2br";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import { Button, Header, Modal } from "semantic-ui-react";
import GameHomeContainer from "./GameHomeContainer";
import Login from "./Login";
import LoginAutomatic from "./LoginAutomatic";

class GameHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.redirectIfUserAuthenticatedSameGame();

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    this.setState({ open: true });
  }

  closeModal() {
    this.setState({ open: false });
  }

  componentDidUpdate() {
    this.redirectIfUserAuthenticatedSameGame();
  }

  redirectIfUserAuthenticatedSameGame() {
    if (
      this.props.auth.user &&
      this.props.auth.user.gameUrl === this.props.match.params.gameurlkey
    ) {
      this.props.history.push(
        "/" + this.props.match.params.gameurlkey + "/resume"
      );
    }
  }

  renderLoginAutomatic() {
    return (
      <LoginAutomatic
        gameId={this.props.game.id}
        gameURL={this.props.match.params.gameurlkey}
      />
    );
  }

  renderLogin() {
    return (
      <Login
        gameId={this.props.game.id}
        gameURL={this.props.match.params.gameurlkey}
      />
    );
  }

  renderContinue() {
    // When anonymous game, the loading of the user is done in <Game>
    return (
      <Button
        fluid
        size="large"
        href={"/" + this.props.match.params.gameurlkey + "/resume"}
      >
        Continuer
      </Button>
    );
  }

  renderDescModal() {
    const { open } = this.state;
    const desc = this.props.game.configuration.desc;

    return (
      <div>
        <p>{desc.mainHtml ? renderHTML(desc.main) : nl2br(desc.main)}</p>
        {desc.second && <a onClick={this.showModal}>Voir plus</a>}
        {desc.cgu && (
          <span className="game-home-cgu"> {renderHTML(desc.cgu)} </span>
        )}
        <Modal open={open} onClose={this.closeModal} closeIcon>
          <Modal.Content scrolling>
            <Modal.Description className="game-home-desc-modal">
              {nl2br(desc.second)}
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }

  render() {
    const mobile = this.props.mobile;

    const action = this.props.game.configuration.hasLoginAutomatic
      ? this.renderLoginAutomatic()
      : this.renderLogin();

    const styleHeader = {
      fontSize: mobile ? "3rem" : "4rem",
      marginTop: mobile ? "80px" : "120px",
    };
    const styleDesc = {
      fontSize: mobile ? "1rem" : "1.5rem",
    };

    const styleCgu = {
      fontSize: mobile ? "0.8rem" : "1.2rem",
      lineHeight: "1.2",
    };

    const styleAction = {
      marginBottom: mobile ? "20px" : "40px",
    };

    const desc = this.props.game.configuration.desc;

    return (
      <GameHomeContainer>
        <Header
          className="game-home-header"
          as="h1"
          textAlign="center"
          style={styleHeader}
        >
          {this.props.game.configuration.title}
        </Header>
        <div className="game-home-desc" style={styleDesc}>
          {mobile ? (
            this.renderDescModal()
          ) : (
            <React.Fragment>
              <p>
                {desc.mainHtml ? renderHTML(desc.main) : nl2br(desc.main)}
                <br />
                {nl2br(desc.second)}
              </p>
              <p>
                {desc.cgu && (
                  <span className="game-home-cgu" style={styleCgu}>
                    {renderHTML(desc.cgu)}
                  </span>
                )}
              </p>
            </React.Fragment>
          )}
        </div>
        <div className="game-home-action" style={styleAction}>
          {action}
        </div>
      </GameHomeContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  game: state.game,
  mobile: state.configuration.mobile,
});

export default connect(mapStateToProps, null)(GameHome);
