import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import _ from "lodash";
import nl2br from "react-nl2br";

import { Card, Label, Header } from "semantic-ui-react";

import LogoutButton from "../Common/LogoutButton";
import GameContainer from "../App/GameContainer";

import "./gameResume.css";

class GameResume extends Component {
  constructor(props) {
    super(props);

    this.renderStory = this.renderStory.bind(this);
  }

  renderStory(story) {
    var { id, name, rankOrder } = story;
    var disabled = false;

    // We need to process the status of the story.
    // Check in the this.props.matches
    // - If nothing there, the story isnt started yet
    // - If status=1: The story is Ongoing
    // - If status=2: The story is Completed
    var status = "";
    if (this.props.matches[id] === undefined) {
      status = "Non commencé";
    } else if (this.props.matches[id].status === 1) {
      status = "En cours";
    } else if (this.props.matches[id].status === 2) {
      status = "Terminé";
    }

    // Determine if the story must be disabled:
    // If stories cannot be replayed and this one is terminated, disable
    if (
      !this.props.game.configuration.canReplayStories &&
      status === "Terminé"
    ) {
      disabled = true;
    }
    // If we have specific stories order, 2 cases:
    // - player.currentStoryId not set, we enable only the story with the rankOrder = 1
    // - player.currentStoryId set, we disable if the storyId is not the same
    else if (this.props.game.configuration.specificStoriesOrder) {
      if (this.props.player.currentStoryId === null) {
        if (rankOrder !== 1) {
          disabled = true;
        }
      } else {
        if (this.props.player.currentStoryId !== id) {
          disabled = true;
        }
      }
    }

    // If the "specificStoriesOrder" option is enabled,
    // and the story is not the current story of the user, we disable it:

    // If disabled, we:
    // - Add CSS to make it look like disabled
    // - Remove the link as well
    if (disabled) {
      return (
        <Card key={id} fluid className="disabled">
          <Card.Content>
            <Label horizontal>{status}</Label>
            {name}
          </Card.Content>
        </Card>
      );
    }

    // Not disabled
    const url = "/" + this.props.match.params.gameurlkey + "/play/" + id;
    return (
      <Card as={Link} key={id} fluid to={url}>
        <Card.Content>
          <Label horizontal>{status}</Label>
          {name}
        </Card.Content>
      </Card>
    );
  }

  renderSubHeader() {
    const resume = this.props.game.configuration.resume;

    if (!resume) {
      return "";
    }

    // Handle image, if any
    var img = "";
    if (resume.subheader_img) {
      img = (
        <div className="game-resume-subheader-image-container">
          <img
            className="game-resume-subheader-image"
            src={resume.subheader_img}
            alt=""
          />
        </div>
      );
    }

    // Handle text, if any
    var text = "";

    if (resume.subheader_txt) {
      text = (
        <div className="game-resume-subheader-text">
          {resume.subheaderHtml
            ? renderHTML(resume.subheader_txt)
            : nl2br(resume.subheader_txt)}
        </div>
      );
    }

    return (
      <div className="game-resume-subheader">
        {img}
        {text}
      </div>
    );
  }

  render() {
    return (
      <GameContainer>
        <div className="game-resume-header">
          <Header as="h1" textAlign="center">
            {this.props.game.configuration.resume.hideHeader
              ? ""
              : this.props.game.name}
          </Header>
          {this.props.game.configuration.hideLogout ? "" : <LogoutButton />}
        </div>
        {this.renderSubHeader()}
        <Card.Group className="game-card">
          {_.map(this.props.stories, this.renderStory)}
        </Card.Group>
      </GameContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  game: state.game,
  player: state.player,
  matches: state.matches,
  stories: state.stories,
});

export default connect(mapStateToProps, null)(GameResume);
