import React, { Component } from "react";
import { connect } from "react-redux";

import { Icon, Button } from "semantic-ui-react";

import { logoutUser } from "../../actions/authActions";

class LogoutButton extends Component {
  onClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }

  render() {
    const styleButton = {
      backgroundColor: "transparent",
      border: 0,
      padding: 0,
      position: "absolute",
      right: "5px",
      top: "7px"
    };

    if (this.props.auth.user.isAnonymous) {
      return "";
    }

    return (
      <Button
        icon
        size="massive"
        style={styleButton}
        onClick={event => this.onClick(event)}
      >
        <Icon name="close" />
      </Button>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(LogoutButton);
