import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import Message from "./Message/Message";

import EventBus, { EVENT_SCROLL_TOBOTTOM } from "./../../util/EventBus";

class ChatContent extends Component {
  constructor(props) {
    super(props);

    this.renderMessage = this.renderMessage.bind(this);

    // 2 possibilities to scroll to bottom:
    // V1: Create messageContainer and use scrollHeight/clientHeight to scroll to bottom
    // this.messageContainer = React.createRef();

    // V2 Create an empty element at the end and use scrollIntoView()
    this.messageEnd = React.createRef();

    EventBus.on(EVENT_SCROLL_TOBOTTOM, this.scrollToBottom);
  }

  componentWillUnmount() {
    EventBus.off(EVENT_SCROLL_TOBOTTOM, this.scrollToBottom);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    EventBus.emit(EVENT_SCROLL_TOBOTTOM, null, "client");
  }

  scrollToBottom = () => {
    // V1
    // const messageContainer = this.messageContainer.current;
    // const scrollHeight = messageContainer.scrollHeight;
    // const height = messageContainer.clientHeight;
    // const maxScrollTop = scrollHeight - height;
    // // messageContainer.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    // ReactDOM.findDOMNode(messageContainer).scrollTop =
    //   maxScrollTop > 0 ? maxScrollTop : 0;

    // // V2
    // const messageEnd = this.messageEnd.current;
    // messageEnd.scrollIntoView({ behavior: "smooth" });

    // V3
    // Added a timeout, when loading a conversation, it is triggered many times, and do not scroll to the last message. With the timer, it let the display shows all the messages before scrolling
    setTimeout(() => {
      const messageEnd = this.messageEnd.current;
      // messageEnd.scrollIntoView();
      messageEnd.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    }, 150);
  };

  renderMessage(message) {
    return <Message key={message.messageId} message={message} />;
  }

  render() {
    return (
      // Create a reference "messageContainer" to be able to scroll properly to the bottom when a change in the display is made
      // V1
      // <div className="chat-content-container" ref={this.messageContainer}>
      <div className="chat-content-container">
        <div className="chat-content">
          {_.map(this.props.renderedSteps, this.renderMessage)}
        </div>
        {/* V2 */}
        <div style={{ float: "left", clear: "both" }} ref={this.messageEnd} />
      </div>
    );
  }
}

ChatContent.propTypes = {
  renderedSteps: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  renderedSteps: state.story.renderedSteps
});

export default connect(
  mapStateToProps,
  null
)(ChatContent);
