import React, { Component } from "react";
import { connect } from "react-redux";

import { Header } from "semantic-ui-react";

import LogoutButton from "./../Common/LogoutButton";
import BackButton from "./../Common/BackButton";
import ResetButton from "./../Common/ResetButton";

class ChatHeader extends Component {
  render() {
    return (
      <div
        className="chat-header"
        style={this.props.game.configuration.styleChatHeader}
      >
        <Header as="h1" textAlign="center">
          {this.props.game.name}
        </Header>
        <BackButton href={"/" + this.props.game.urlkey + "/resume"} />
        {this.props.game.configuration.canReplayStories ? <ResetButton /> : ""}
        {this.props.game.configuration.hideLogout ? "" : <LogoutButton />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  game: state.game,
});

export default connect(mapStateToProps, null)(ChatHeader);
