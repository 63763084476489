import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loginAnonymous } from "../../actions/authActions";

class LoginAnonymous extends Component {
  constructor(props) {
    super(props);
    this.props.loginAnonymous(this.props.gameId);
  }

  render() {
    return null;
  }
}

LoginAnonymous.propTypes = {
  gameId: PropTypes.number.isRequired,
  loginAnonymous: PropTypes.func.isRequired
};

export default connect(
  null,
  { loginAnonymous }
)(LoginAnonymous);
