import {
  CLEAR_MESSAGE,
  SET_MESSAGE,
  SET_FORM_ERRORS,
  CLEAR_FORM_ERRORS
} from "./types";

export const handleError = err => {
  if (((err.response || {}).data || {}).message === "Invalid input") {
    return {
      type: SET_FORM_ERRORS,
      payload: err.response.data.errors
    };
  } else {
    return {
      type: SET_MESSAGE,
      payload: {
        header: "Erreur",
        content: "Une erreur est survenue"
      }
    };
  }
};

export const handleMessage = (content, header = "") => {
  return {
    type: SET_MESSAGE,
    payload: {
      header: header,
      content: content
    }
  };
};

export const clearMessage = () => dispatch => {
  dispatch({
    type: CLEAR_MESSAGE
  });
};

export const clearFormError = () => dispatch => {
  dispatch({
    type: CLEAR_FORM_ERRORS
  });
};
