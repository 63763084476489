import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { loading } from '../../common/animations';

class LoadingStep extends Component {
  render() {
    const style = {
      animation: `${loading} 1.4s infinite both`,
      animationDelay: this.props.delay
    };
    return <span style={style}>.</span>;
  }
}

LoadingStep.propTypes = {
  delay: PropTypes.string.isRequired
};

export default LoadingStep;
