import React, { Component } from "react";

import { Router, Route, Switch } from "react-router-dom";

import customHistory from "../../history";

import ResponsiveHandler from "./ResponsiveHandler";
import MessageHandler from "./MessageHandler";
import PageNotFound from "../Common/PageNotFound";
import Game from "./Game";

import "./app.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { color: "#3C454B" };

    window.addEventListener("resize", this.updateAppHeight);
    this.updateAppHeight();
  }

  updateAppHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  render() {
    return (
      <div className="app">
        <MessageHandler>
          <ResponsiveHandler />
          <Router history={customHistory}>
            <Switch>
              <Route path="/:gameurlkey" component={Game} />
              <Route component={PageNotFound} />
            </Switch>
          </Router>
        </MessageHandler>
      </div>
    );
  }
}

export default App;
