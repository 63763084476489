import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import renderHTML from "react-render-html";

import Loading from "../../Common/Loading";

class MessageList extends Component {
  constructor(props) {
    super(props);

    this.renderElement = this.renderElement.bind(this);
  }

  renderElement(element, key) {
    const { label, html } = element;
    return (
      <li key={key} className="chat-message-list-option">
        {html ? renderHTML(label) : label}
      </li>
    );
  }

  render() {
    return (
      <div className="chat-message-content" style={this.props.style}>
        <div className="chat-message-text" style={this.props.styleText}>
          {this.props.loading ? (
            <Loading />
          ) : (
            <ul className="chat-message-list">
              {_.map(this.props.content, this.renderElement)}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

MessageList.propTypes = {
  loading: PropTypes.bool,
  content: PropTypes.array.isRequired,
  style: PropTypes.object.isRequired,
  styleText: PropTypes.object.isRequired
};

export default MessageList;
