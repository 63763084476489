import React from 'react';
import LoadingStep from './LoadingStep';

const Loading = () => (
  <span className="rsc-loading">
    <LoadingStep delay="0s" />
    <LoadingStep delay=".2s" />
    <LoadingStep delay=".4s" />
  </span>
);

export default Loading;
