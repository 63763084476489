import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import renderHTML from "react-render-html";

import { Button, Modal } from "semantic-ui-react";

import SubmitButton from "./SubmitButton";
import isEmpty from "../../../util/is-empty";

class PopupField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.renderModal = this.renderModal.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.renderTrigger = this.renderTrigger.bind(this);
  }

  onChange(value) {
    this.setState({ data: value });
  }

  onSubmit() {
    this.props.onSubmit(this.state.data);
  }

  renderTrigger() {
    var buttonLabel = "Réponses";
    if (!isEmpty(this.state.data)) {
      const { label, html } = this.props.options[this.state.data];
      buttonLabel = html ? renderHTML(label) : label;
    }

    return (
      <Button
        className="chat-input-selectpopup-button"
        type="button"
        style={this.props.styleOptionElement}
      >
        {buttonLabel}
      </Button>
    );
  }

  renderOption(option) {
    const { value, label, html } = option;

    let style = _.merge({}, this.props.styleOptionElement);
    if (value === this.state.data) {
      _.merge(style, this.props.styleOptionElementSelected);
    }

    return (
      <li
        key={value}
        className="chat-input-selectpopup-option"
        style={this.props.styleOption}
      >
        <a
          style={style}
          className="chat-input-selectpopup-option-element"
          value={value}
          onClick={() => this.onChange(value)}
        >
          {html ? renderHTML(label) : label}
        </a>
      </li>
    );
  }

  renderOptions() {
    return (
      <ul className="chat-input-selectpopup-options">
        {_.map(this.props.options, this.renderOption)}
      </ul>
    );
  }

  renderModal() {
    return (
      <Modal
        trigger={this.renderTrigger()}
        closeIcon
        className="chat-input-selectpopup-modal"
      >
        <Modal.Header>Réponses</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>{this.renderOptions()}</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onSubmit}>Envoyer</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    var classes_modal_container = "chat-input-selectpopup-modal-container";
    var submit = "";

    if (!isEmpty(this.state.data)) {
      classes_modal_container +=
        " chat-input-selectpopup-modal-container-submit";
      submit = <SubmitButton onSubmit={this.onSubmit} />;
    }

    var modal = this.renderModal();

    return (
      <div>
        <div className="chat-input-selectpopup-container">
          <div className={classes_modal_container}>{modal}</div>
          {submit}
        </div>
      </div>
    );
  }
}

PopupField.propTypes = {
  options: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  styleOption: PropTypes.object.isRequired,
  styleOptionElement: PropTypes.object.isRequired,
  styleOptionElementSelected: PropTypes.object.isRequired
};

export default PopupField;
