import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Responsive } from 'semantic-ui-react';

import { setMobile } from '../../actions/confActions';

class ResponsibleSetter extends Component {
  constructor(props) {
    super(props);

    this.props.setMobile(this.props.mobile);
  }

  componentDidUpdate(prevProps) {
    if (this.props.mobile !== prevProps.mobile) {
      this.props.setMobile(this.props.mobile);
    }
  }
  render() {
    return <div />;
  }
}

ResponsibleSetter.propTypes = {
  setMobile: PropTypes.func.isRequired,
  mobile: PropTypes.bool
};

class ResponsiveHandler extends Component {
  render() {
    return (
      <div>
        <Responsive
          minWidth={Responsive.onlyTablet.minWidth}
          className="app-desktop"
        >
          <ResponsibleSetter mobile={false} setMobile={this.props.setMobile} />
        </Responsive>
        <Responsive
          maxWidth={Responsive.onlyMobile.maxWidth}
          className="app-mobile"
        >
          <ResponsibleSetter mobile={true} setMobile={this.props.setMobile} />
        </Responsive>
      </div>
    );
  }
}

ResponsiveHandler.propTypes = {
  setMobile: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    setMobile
  }
)(ResponsiveHandler);
