import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Icon, Button } from 'semantic-ui-react';

class BackButton extends Component {
  render() {
    const styleButton = {
      backgroundColor: 'transparent',
      border: 0,
      padding: 0,
      position: 'absolute',
      left: '5px',
      top: '7px'
    };

    return (
      <Button
        as={Link}
        icon
        size="massive"
        style={styleButton}
        to={this.props.href}
      >
        <Icon name="left arrow" />
      </Button>
    );
  }
}

BackButton.propTypes = {
  href: PropTypes.string.isRequired
};

export default BackButton;
