import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { Form, Button } from "semantic-ui-react";

import { loginAutomaticUser } from "../../actions/authActions";

class LoginAutomatic extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.loginAutomaticUser(this.props.gameId);
  }

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Button fluid size="large">
          Commencer
        </Button>
      </Form>
    );
  }
}

loginAutomaticUser.propTypes = {
  gameId: PropTypes.number.isRequired,
  gameURL: PropTypes.string.isRequired,
  loginAutomaticUser: PropTypes.func.isRequired,
};

export default connect(null, {
  loginAutomaticUser,
})(LoginAutomatic);
