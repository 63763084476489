import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import customHistory from "../../../history";

import { resetStory } from "../../../actions/gameActions";
import { clearInput } from "../../../actions/inputActions";

class ActionsField extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.renderAction = this.renderAction.bind(this);
  }

  // Data contains .value and .message
  onSubmit(data) {
    // Trigger different kind of action according to the data
    let actionTriggered = this.props.input.actions[data];

    if (actionTriggered.action === "reset") {
      this.props.resetStory(
        this.props.game.urlkey,
        this.props.storyId,
        actionTriggered.trigger,
        this.props.input.stepId,
        data
      );
    } else if (actionTriggered.action === "resume") {
      customHistory.push("/" + this.props.game.urlkey + "/resume");
    }

    // Remove the input
    this.props.clearInput();
  }

  renderAction(action) {
    const { value, label } = action;

    let style = _.merge({}, this.props.styleActionElement);

    return (
      <li
        key={value}
        className="chat-input-action"
        style={this.props.styleAction}
      >
        <a
          style={style}
          className="chat-input-action-element"
          value={value}
          onClick={() => this.onSubmit(value)}
        >
          {label}
        </a>
      </li>
    );
  }

  render() {
    return (
      <div className="chat-input-actions-container">
        <ul className="chat-input-actions">
          {_.map(this.props.input.actions, this.renderAction)}
        </ul>
      </div>
    );
  }
}

ActionsField.propTypes = {
  resetStory: PropTypes.func.isRequired,
  clearInput: PropTypes.func.isRequired,
  styleAction: PropTypes.object.isRequired,
  styleActionElement: PropTypes.object.isRequired,
  styleActionElementSelected: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  game: state.game,
  input: state.story.input,
  storyId: state.story.id
});

export default connect(
  mapStateToProps,
  { resetStory, clearInput }
)(ActionsField);
