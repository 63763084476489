import React, { Component } from "react";
import PropTypes from "prop-types";

import Loading from "../../Common/Loading";

class MessageImage extends Component {
  constructor(props) {
    super(props);
    this.state = { imageLoaded: false };
    this.onLoad = this.onLoad.bind(this);
  }

  // Triggered when the image is loaded
  onLoad() {
    this.setState({ imageLoaded: true });
  }

  render() {
    // Display an hidden image, to load it
    // When loaded, it trigger this.onLoad, which allows to render the true image

    return (
      <div className="chat-message-content" style={this.props.style}>
        {this.props.loading || !this.state.imageLoaded ? (
          <div className="chat-message-text" style={this.props.styleText}>
            <Loading />
          </div>
        ) : (
          <img
            className="chat-message-image"
            style={this.props.styleImage}
            src={this.props.content.src}
            alt={this.props.content.alt}
          />
        )}
        <div style={{ display: "none" }}>
          <img src={this.props.content.src} alt="" onLoad={this.onLoad} />
        </div>
      </div>
    );
  }
}

MessageImage.propTypes = {
  loading: PropTypes.bool,
  content: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }),

  style: PropTypes.object.isRequired,
  styleText: PropTypes.object.isRequired,
  styleImage: PropTypes.object.isRequired
};

export default MessageImage;
