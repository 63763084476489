import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Grid } from "semantic-ui-react";

import "./gameContainer.css";

class GameContainer extends Component {
  render() {
    return (
      <div
        className="game-container"
        style={this.props.game.configuration.styleGame}
      >
        <Container text>
          <Grid
            textAlign="center"
            className="game-container-grid"
            style={this.props.game.configuration.styleGameGrid}
          >
            <Grid.Column
              className="game-container-grid-column"
              style={this.props.game.configuration.styleGameGridColumn}
            >
              {this.props.children}
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  game: state.game
});

export default connect(
  mapStateToProps,
  null
)(GameContainer);
